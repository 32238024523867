import React  from "react";
import {Text,Heading,Box,Flex, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import MeinSeo from '../components/seo'
import {motion} from 'framer-motion'
export default function MayerOberstein(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >  
        <MeinSeo title="Das Schicksal der jüdischen Familie Mayer aus Oberwesel, Oberstr." description="Bilder und Dokumente zum Schicksal der Familie Mayer aus Oberwesel, Oberstr." />
        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid py="8" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading variant="utitel" >Oberstraße 1 und 3
            <br/>
            Berthold Mayer und Emma Perlstein, geb. Mayer

            </Heading>
            
            <Text variant="solid">
            Die Familie Mayer gehört zu den ältesten einheimischen Familien, auf der napoleonischen Erfassungsliste von 1808 stehen neun ihrer Mitglieder. Der Kaufmann Emanuel Mayer (1806–1891) ließ 1872 ein stattliches Wohn- und Geschäftshaus auf dem Schaarplatz erbauen, Oberstraße 1. Das Nachbarhaus Oberstraße 3 gehörte dazu.
Er verkaufte das Haus 1881 Johann Fey, der es zu einem Hotel umbaute, das einen 4,30 Meter hohen Tanzsaal in der Belle Etage hatte. Sein Sohn Heinrich Fey verkaufte es in den 20er Jahren an Bernhard Alt. Das Haus Oberstraße 3 übernahm Emanuels Sohn Alexander Mayer (1855–1912), verheiratet mit Pauline, geb. Neumann, aus Stadecken bei Mainz. Hier hatte er eine Metzgerei. In der Oberstraße 3 hatte 1928 der Kaufmann Theodor Lichtenstein sein Geschäft.<br/>
Als Vorsteher der Gemeinde betrieb Alexander Mayer den Bau der 1886 eingeweihten neuen Synagoge auf dem Schaarplatz. Seine Frau Pauline gehörte 50 Jahre lang dem Israelitischen Wohltätigkeits- und Bestattungsverein an. Ihre Kinder sind Emma (*1880), verheiratete Perlstein, und der Bankier Berthold Mayer (*1882), verheiratet mit Berta Sörensen aus Köln. Deren Söhne sind Kurt (*1921) und Helmuth (*1924).<br/>
Alexander Mayer wurde 1912 unter großer Anteilnahme der Bevölkerung auf dem jüdischen Friedhof bestattet. Seine Frau Pauline folgte ihm 1930. 
            </Text>
            <Heading variant="utitel">19. September 1936</Heading>
            <Text variant="solid">In Köln stirbt Berthold Mayer, der Sohn von Alexander und Pauline Mayer. Er ist Mitinhaber des Kölner Bankhauses Basten, Mayer &amp; Cie. (Auf dem Berlich 45). Die Familie wohnt am Duffesbach 8a. Berthold Mayer wird auf dem Friedhof von Oberwesel bestattet. Das Bankhaus Basten, Mayer &amp; Cie. wird wenig später von dem »arischen« Konkurrenten Walter Wingerath übernommen.</Text>
            <Heading variant="utitel">Herbst 1941</Heading>
            <Text variant="solid">Kurt Mayer gelingt die Flucht nach Australien. Vermutlich war er vorher mit seinem Bruder in der jüdischen Auswanderungsschule Groß Breesen bei Trebnitz in Schlesien. 1942 wird er in Sydney zum Militär eingezogen. 1945 erwirbt er die australische Staatsbürgerschaft.</Text>
            <Heading variant="utitel">10. Dezember 1940</Heading>
            <Text variant="solid">Alexander Mayers Tochter Emma Perlstein (*1880) bekommt ein Schiff in die Karibik und nimmt von Ciudad Trujillo, der Hauptstadt der Dominikanischen Republik, am 04.03.1941 den Dampfer »Borinquen« nach New York. Sie hat die Höhere-Töchter-Schule (heute Frauenlob-Gymnasium) in Mainz besucht (die Schulakte von 1893/94 vermerkt „zwei verschiedene Augen“ und ihre Adresse bei Familie Stein, Löwenhofstraße 5). Sie hatte Julius Perlstein (*1874) geheiratet, geboren im nordhessischen Meimbressen, und war zu ihm nach Kassel gezogen. Sie bekamen zwei Kinder: Flora (*1901) und Fritz (*1904). Julius Perlstein starb am 04.03.1930, er ist in Oberwesel beigesetzt. Emma Perlstein stirbt am 10.09.1962 in Los Angeles. Ihre Tochter Flora heiratet Alfred Weis (*1903), sie bekommen die Tochter Tana (*1939) und fliehen nach Palästina, wo sie 1943 eingebürgert werden. Flora arbeitet als Ärztin. Ihr Bruder Fritz, verheiratet mit Stefanie Freimark aus Würzburg, konnte schon am 17.04.1939 über die Dominikanische Republik in die USA fliehen. Ihre Kinder sind Sandra Leah (*1948), Jules (*1952) und Ruth (*1954).</Text>
            <Heading variant="utitel">1942</Heading>
            <Text variant="solid">Helmuth Mayer, geboren in Köln als Sohn von Berthold Mayer und Berta Sörensen, wird von der jüdischen Auswanderungsschule Lehrgut Groß Breesen nach Auschwitz deportiert. Der Kölner Rechtsanwalt Hans Israel (ermordet 1942) war nach dem Tod des Vaters zum Vormund der Brüder Kurt und Helmuth Mayer bestellt worden</Text>
            <Heading variant="utitel">27. Januar 1945</Heading>
            <Text variant="solid">Helmuth Mayer wird durch die »Rote Armee« befreit. Er kommt nach Bonn, von wo er am 25.7.1945 mit einem Omnibus nach Oberwesel gebracht wird, zusammen mit den Überlebenden der Familie Lichtenstein. Seine Mutter hat in der Zwischenzeit den Kölner Schriftsteller und Schauspieler Dr. Raoul Konén (1880–1966) geheiratet, sie wohnen in Köln-Lindenthal. Zum 01.01.1946 wird er dem DP-Lager Deggendorf zugewiesen. Von dort kann er am 09.11.1946 über Hamburg mit dem Dampfer »Marine Marlin« nach New York fahren, begleitet von seiner Frau Gertrud Mayer Kraus. Sie kommen am 24.01.1947 in New York an. Er stirbt am 11.07.2009 in Florida.</Text>
           
            </Box>
            <Box pt="10">
                <Flex flexWrap="wrap" columns={["column","row"]} >
                <Box p="1">
                    <StaticImage src="../images/solo/Berthold-Mayer-Grab.jpg" />                    <Text variant="Bild"> Das Grab von Bertold Mayer</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/Haus-Mayer-Oberstr.jpg" /> 
                    <Text variant="Bild"> Das Haus in der Oberstraße</Text>
                </Box>
               
                
               
               </Flex>
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}